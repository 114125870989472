<template>
    <div>
        <CRow> 
            <CCol md="12">   
                <CButton :color="menu == 'general' ? 'info' : 'secondary'" size="sm" @click="menu != 'general' ? showGraph('general') : '';" style="margin-right: 5px;">Resumen General</CButton>
                <CButton :color="menu == 'work-pending' ? 'info' : 'secondary'" size="sm" @click="menu != 'work-pending' ? showGraph('work-pending') : '';" style="margin-right: 5px;">Trabajo Pendiente</CButton>
                <CButton :color="menu == 'kams-supports' ? 'info' : 'secondary'" size="sm" @click="menu != 'kams-supports' ? showGraph('kams-supports') : '';" style="margin-right: 5px;">Promotores / Ciudades Soporte</CButton>
                <CButton :color="menu == 'kams-boss' ? 'info' : 'secondary'" size="sm" @click="menu != 'kams-boss' ? showGraph('kams-boss') : '';" style="margin-right: 5px; width: 120px;">Marcas Kam</CButton>
                <CButton :color="menu == 'generics-branches' ? 'info' : 'secondary'" size="sm" @click="menu != 'generics-branches' ? showGraph('generics-branches') : '';" style="margin-right: 5px;">Marca/Sucursales Rezagadas</CButton>
                <CButton :color="menu == 'customer-contracts' ? 'info' : 'secondary'" size="sm" @click="menu != 'customer-contracts' ? showGraph('customer-contracts') : '';" style="margin-right: 5px;">Universo Cliente</CButton>
                <CButton color="success" size="sm" @click="showImporter();" style="float: right;">Importador de Visitas</CButton>
            </CCol>           
        </CRow>
    </div>
</template>

<script>

export default {
    name: 'GraphsMenu',
    props: {
        menu: {
            type: String,
            default() {
                return ""
            }
        },
    },
    components : {
	},
    data: function () {
		return {
            items: {},
            root: "",
        }
    },
    computed: {
        
    },
    methods: {
        showGraph: async function(graph){
            window.location = '/#/graphs/'+graph;
        },
        showImporter: async function(){
            let location = window.location+"";
            if(location.indexOf('/#/visits/importer') == -1){
                window.location = '/#/visits/importer';
            }
            else{
                this.$router.go(0);
            }
        },
    },
  	mounted: async function(){        
    },
}

</script>
