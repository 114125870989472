<template>
    <CModal title="Importar Visitas" :color="colorModal" :show.sync="showModal">
        <p style="font-weight: bold;">Archivo a Importar:</p>
        <CForm id="import_file_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="file"
                    id="file"
                    :lazy="false"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                En el procedimiento se validarán las frecuencias, la existencia de la marca, la cadena y la sucursal, por lo que es importante que el archivo contenga los siguientes campos:
                <br /><br />
                <ul>
                    <li>Fecha (Si esta dentro de la frecuencia)</li>
                    <li>Marca (que sea la misma que este contrato)</li>
                    <li>Cadena</li>
                    <li>Sucursal</li>
                </ul>
              </CCol>
            </CRow>
        </CForm>
        <template #header>
          <h5 class="modal-title">Importar Visitas</h5>
          <CButtonClose @click="showModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="showModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="importProcess" color="success">Importar</CButton>
        </template>
    </CModal>
</template>

<script>

import store from '../../../store'
import ws from '../../../services/admin';

export default {
  name: 'ImportingModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      colorModal: 'info',
      contract_id: 0,
      store_id: 0,
      branch_id: 0,
      stores: [],
      branchs: []
    }
  },
  methods: {
    async show (contract_id, stores) {
      this.showModal = true;

      this.loading();

      // let response = await ws.getStoreBranchs(this.contract_id, this.store_id); 

      // if(response.type == "success"){
      //   this.branchs = response.data;

      //   this.branch_id = this.branchs[0].id+"";
      // }

      this.loaded();
    },
    async importProcess(data) {
      this.loading();

      const form = document.getElementById("import_file_form");
      const formData = new FormData(form);

      let response = await ws.uploadFormFile(formData);

      if(response.type == "success"){
        this.$emit("imported",response.data);
      }

      this.showToast(response.type, response.message);

      this.hide();

      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    hide () {
      this.showModal = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>